import {
  IUserDefaults,
  IUserFlags,
  IUserProfileDefaults,
  IUserSubAccountDefaults,
  UserPreferences,
  UserPreferencesBeforeConvert,
} from "@/models/UI";
import { Capacity } from "@/models/trading";
import { useAuthStore } from "@/store/authStore";

export const useUserPreferences = () => {
  const DEFAULT_FLAGS: IUserFlags = {
    insertOrderOrDouble: false,
    cancelAllActiveOrders: false,
    suspendAllActiveOrders: false,
    deleteActiveOrder: false,
    suspendActiveOrder: false,
    reduceActiveOrder: false,
    resubmitActiveOrder: false,
    editSuspendOrder: false,
    insertUnmatchedDeal: false,
    deleteUnmatchedDeal: false,
    acceptUnmatchedDeal: false,
    editUnmatchedDeal: false,
    splitDeal: false,
    assignDealToMember: false,
    tripartiteDeal: false,
    cumulateDeal: false,
    correctDealPrinciple: false,
    exerciseOption: false,
    abandonOption: false,
    subAccountChange: false,
    deleteSiloCertAuctionBid: false,
  };
  const DEFAULT_PROFILE: IUserProfileDefaults = {
    branch: "",
    dealer: "",
    client: "",
  };
  const DEFAULT_DEFAULTS: IUserDefaults = {
    tradeFormLocation: "button",
    priceChangeMop: 0,
    rateChangeMop: 0,
    volChangeMop: 0,
    maxOrderQuantity: 1,
    defaultOrderQuantity: 1,
    defaultPopupNotificationTime: 0,
    timeout: 0,
    maxDepth: 0,
    capacity: Capacity.PrincipalCapacity,
    soundOnDealExecution: false,
    colorOnUpdates: "text",
  };
  // const DEFAULT_SUBACCOUNTS: IUserSubAccountDefaults = {
  //   defaultSubAcc: "",
  //   userSubAccounts: [],
  // };
  function convertToCamelCaseDefaults(defaults: any): IUserDefaults {
    return {
      priceChangeMop: defaults.priceChangeMop || defaults.price_change_mop || 0,
      rateChangeMop: defaults.rateChangeMop || defaults.rate_change_mop || 0,
      volChangeMop: defaults.volChangeMop || defaults.vol_change_mop || 0,
      maxOrderQuantity:
        defaults.maxOrderQuantity || defaults.max_order_quantity || 1,
      defaultOrderQuantity:
        defaults.defaultOrderQuantity || defaults.default_order_quantity || 1,
      defaultPopupNotificationTime:
        defaults.defaultPopupNotificationTime ||
        defaults.default_popup_notification_time ||
        0,
      timeout: defaults.timeout || 0,
      maxDepth: defaults.maxDepth || defaults.max_depth || 0,
      capacity: defaults.capacity || Capacity.PrincipalCapacity,
      soundOnDealExecution:
        defaults.soundOnDealExecution ||
        defaults.sound_on_deal_execution ||
        false,
      colorOnUpdates:
        defaults.colorOnUpdates || defaults.color_on_updates || "text",
      tradeFormLocation: defaults.tradeFormLocation || "button",
    };
  }
  const convertUserPreferences = (
    oldPrefs: UserPreferencesBeforeConvert
  ): UserPreferences => {
    let parsedPref = oldPrefs.userPreferenceJson;

    // hook into consumable for defaults here

    const newPrefs: UserPreferences = {
      flags: parsedPref?.flags ?? oldPrefs.flags ?? DEFAULT_FLAGS,
      profile: parsedPref?.profile ?? oldPrefs.profile ?? DEFAULT_PROFILE,
      defaults:
        convertToCamelCaseDefaults(parsedPref?.defaults || oldPrefs.defaults) ??
        DEFAULT_DEFAULTS,
      // subAccounts:
      //   parsedPref?.subAccounts ?? oldPrefs.subAccounts ?? DEFAULT_SUBACCOUNTS,
      _id: oldPrefs._id,
      userId: oldPrefs.userId,
      userPreferenceJson: null,
    };

    return newPrefs;
  };
  const needsConversion = (prefs: UserPreferencesBeforeConvert): boolean => {
    return !!prefs.userPreferenceJson;
  };
  function createDefaultPreference(): UserPreferences {
    const { DEFAULT_FLAGS, DEFAULT_PROFILE, DEFAULT_DEFAULTS } =
      useUserPreferences();

    const authStore = useAuthStore();
    if (authStore.getHQ) {
      const defaultPreference: UserPreferences = {
        userId: authStore.getHQ.userId, // Assuming getHQ is globally accessible here
        flags: DEFAULT_FLAGS,
        profile: DEFAULT_PROFILE,
        defaults: DEFAULT_DEFAULTS,
        // subAccounts: DEFAULT_SUBACCOUNTS,
        userPreferenceJson: null, // We set this to null since we're using the fully fleshed-out model
      };
      return defaultPreference;
    } else {
      throw "No HQ: Cannot create default";
    }
  }

  return {
    createDefaultPreference,
    needsConversion,
    convertUserPreferences,
    DEFAULT_DEFAULTS,
    DEFAULT_FLAGS,
    DEFAULT_PROFILE,
  };
};
