// Utilities
import { defineStore } from "pinia";
import { MarketDisplayItemContract } from "@/models/marketData";
import { Ref, computed, reactive, ref } from "vue";
import { ILoaders, LoaderStatus } from "@/models/UI";
import { useAppInitLoader } from "@/utils/useAppInitLoader";

type LANGUAGE = "en" | "afr";
type THEME = "light" | "dark";

// export const useAppStore = defineStore("appStore",  {
//   state: {
//     appInitializer: useAppInitLoader();
//     searchPrinciple: ref("");

//   }

// });
export interface AppInitState {
  isOpen: boolean;
  initializing: boolean;
  showError: boolean;
}

export const useAppStore = defineStore("appStore", () => {
  const searchPrinciple: Ref<string | null> = ref(null);
  const searchDealer: Ref<string | null> = ref(null);
  const searchContract: Ref<string | null> = ref(null);
  // Actions
  const isAnySearchActive = computed(() => {
    return (
      searchPrinciple.value != null ||
      searchDealer.value != null ||
      searchContract.value != null
    );
  });

  const appInitState = reactive<AppInitState>({
    isOpen: false,
    initializing: false,
    showError: false,
  });

  const loaders = reactive<ILoaders>({
    userProfile: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded your profile.",
        error: "Error has occured loading your profile",
        loading: "Loading your profile...",
        notStarted: "User profile waiting",
      },
    },
    marketData: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded market data...",
        error: "Error has occured loading market data",
        loading: "Loading market data...",
        notStarted: "Market data waiting",
      },
    },
    members: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded members...",
        error: "Error has occured loading members",
        loading: "Loading members...",
        notStarted: "Members waiting",
      },
    },
    layout: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded your saved layouts.",
        error: "Error has occured loading layouts",
        loading: "Loading your saved layouts...",
        notStarted: "Layouts waiting",
      },
    },
    userPref: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded your preferences.",
        error: "Error loading your preferences",
        loading: "Loading your preferences...",
        notStarted: "User preferences waiting",
      },
    },
    mtm: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded MTM.",
        error: "Error loading MTM",
        loading: "Loading MTM...",
        notStarted: "MTM waiting",
      },
    },
    contractDate: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded Contract Dates.",
        error: "Error loading Contract Dates",
        loading: "Loading Contract Dates...",
        notStarted: "Contract Dates waiting",
      },
    },
    dailyTrend: {
      status: LoaderStatus.NotStarted,
      errorMessage: null,
      text: {
        success: "Loaded Trade History.",
        error: "Error loading Trade History",
        loading: "Loading Trade History...",
        notStarted: "Trade History waiting",
      },
    },
  });

  const appInitializer = useAppInitLoader(loaders, appInitState);

  // The computed property that calculates the progress
  const progress = computed(() => {
    let loaded = 0;
    for (const loader in loaders) {
      if (loaders[loader as keyof ILoaders].status === LoaderStatus.Success) {
        loaded++;
      }
    }
    return (loaded / Object.keys(loaders).length) * 100; // return progress in percentage
  });

  const errorCount = computed(() => {
    let errors = 0;
    for (const loader in loaders) {
      if (loaders[loader as keyof ILoaders].status === LoaderStatus.Error) {
        errors++;
      }
    }
    return errors;
  });

  return {
    searchPrinciple,
    searchDealer,
    searchContract,
    isAnySearchActive,
    appInitializer,
    appInitState,
    errorCount,
    progress,
    loaders,
  };
});
