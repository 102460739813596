import {
  UserLayout,
  UserPreferences,
  UserPreferencesBeforeConvert,
} from "@/models/UI";
import { LoginResponse, Member } from "@/models/auth";
import { LAYOUT } from "@/models/layout";
import {
  DepthContractOverview,
  MarketDisplayItemContract,
  MtmPriceViewModel,
} from "@/models/marketData";
import { Capacity, IUser } from "@/models/trading";
import { axiosInstance, noAuthInstance } from "@/plugins/axios";
import { AxiosError } from "axios";
import useConsoleLogger from "./useConsoleLogger";
import { Tripartite } from "@/models/tripartite";
import { MarketAnnouncement } from "@/store/marketAnnouncements";
import { TwoFactorAuth } from "@/models/2fa";
import { useUserPreferences } from "./useUserPreferences";
import { v4 as uuidv4 } from "uuid";
import { useToastStore } from "@/store/toastStore";

/**
 * Gets the user profile object from HQ
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if call is successful, false otherwise.
 */
export async function getHQAccessProfile(): Promise<IUser | null> {
  // try {
  // Make call to backend to login - all call need /api/{controller}
  const res = await axiosInstance.get("/api/authenticate/getHQAccess");
  if (res && res.data) {
    return res.data;
  }
  return null;
}

/**
 * Performs user authentication using the provided credentials.
 * Talks to server and then to HQ.
 *
 * Triggers by onClick on button
 *
 * Token should be stored in the auth store to allow boolean to be returned.
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function submitLogin(
  username: string,
  password: string
): Promise<LoginResponse | null> {
  const res = await noAuthInstance.post("/api/authenticate/login", {
    email: username,
    password: password,
  });
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
}
/**
 * 2fa
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function submit2fa(
  username: string,
  password: string
): Promise<TwoFactorAuth | null> {
  const body: TwoFactorAuth = {
    email: username,
    password: password,
    code: "",
    securityKey: "",
    scan: "",
    manualEntrySetupCode: "",
    codeValid: false,
    userValid: false,
    showSetup: false,
    error: false,
    errorDescription: "",
  };
  const url = "/api/twoFactorAuth/GetTwoFaDetails";
  const res = await noAuthInstance.post(url, body);
  // const resConfirm = await noAuthInstance.post(url, res.data);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
}
export async function confirm2faReset(otp: string) {
  const url = "/api/twoFactorAuth/OTP2FAConfirmation";
  const res = await noAuthInstance.get(url, {
    params: {
      otp: otp,
    },
  });
  return Promise.resolve(res.data);
}
export async function request2faReset(
  username: string,
  password: string
): Promise<string | null> {
  const url = "/api/twoFactorAuth/TwoFAReset";
  const res = await noAuthInstance.post(url, {
    email: username,
    password,
  });
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
}
export async function confirm2fa(
  body: TwoFactorAuth
): Promise<TwoFactorAuth | null> {
  const url = "/api/twoFactorAuth/GetTwoFaDetails";
  const res = await noAuthInstance.post(url, body);
  // const resConfirm = await noAuthInstance.post(url, res.data);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
}

/**
 * Gets market display array
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function getMarketDisplay(): Promise<MarketDisplayItemContract[]> {
  // try {
  const res = await axiosInstance.get(
    "/api/MarketSubscription/GetMarketDisplay",
    {
      params: {
        publish: true,
      },
    }
  );
  if (res) return Promise.resolve(res.data);
  return [];
  // } catch (err) {
  //   console.error(err);
  //   return Promise.reject(err);
  // }
}
/**
 * Gets all members array
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function getMembers(): Promise<Member[]> {
  try {
    const res = await axiosInstance.get(
      "/api/StaticData/GetAllMembers"
      // {
      //   params: {
      //     publish: true,
      //   },
      // }
    );
    if (res) return Promise.resolve(res.data);
    return [];
  } catch (err) {
    useConsoleLogger.error(err);
    return Promise.reject(err);
  }
}

/**
 * Gets all tripartite
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function getTripartites(): Promise<Tripartite[]> {
  try {
    const res = await axiosInstance.get(
      "/api/StaticData/GetTripartites"
      // {
      //   params: {
      //     publish: true,
      //   },
      // }
    );
    if (res) return Promise.resolve(res.data);
    return [];
  } catch (err) {
    useConsoleLogger.error(err);
    return Promise.reject(err);
  }
}

/**
 * Create user preference - flags, defaults, layouts etc
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function createUserPref(
  item: UserPreferences
): Promise<UserPreferences> {
  const body = JSON.stringify(item.userPreferenceJson);
  const res = await axiosInstance.post(
    "/api/UserSettings/CreateUserPreference",
    item
  );
  return res.data;
}

/**
 * Laod user preference - flags, defaults, layouts etc
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function loadUserPref(): Promise<UserPreferences> {
  // try {
  const { needsConversion, convertUserPreferences } = useUserPreferences();

  const res = await axiosInstance.get("/api/UserSettings/GetUserPreference");

  const oldPrefs: UserPreferencesBeforeConvert = {
    ...res.data,
    userPreferenceJson: JSON.parse(res.data.userPreferenceJson),
  };
  if (needsConversion(oldPrefs)) {
    const convertedPrefs = convertUserPreferences(oldPrefs);
    await updateUserPref(convertedPrefs);
    return convertedPrefs;
  } else {
    return oldPrefs as UserPreferences; // casting as UserPreferences since the only difference is potential null values
  }
  // } catch (err) {
  //   useConsoleLogger.error(err);
  //   if (err instanceof AxiosError) {
  //     if (err.response && err.response.status == 405) {
  //       return Promise.resolve(undefined);
  //     }
  //   }

  //   return Promise.reject(err);
  // }
}
/**
 * Update user preference - flags, defaults, layouts etc
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function updateUserPref(
  item: UserPreferences
): Promise<UserPreferences> {
  // try {
  const res = await axiosInstance.post(
    "/api/UserSettings/UpdateUserPreference",
    item
  );
  return res.data;
  // } catch (err) {
  //   console.error(err);
  //   return Promise.reject(err);
  // }
}
/**
 * Load user layouts
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if .
 */
export async function loadUserLayoutAPI(): Promise<UserLayout[]> {
  // try {
  interface LayoutRes {
    userId: string;
    userLayoutJson: string;
    id?: number | string;
  }
  const res = await axiosInstance.get<LayoutRes[]>(
    "/api/UserSettings/GetAllUserLayouts"
  );
  const arr: UserLayout[] = [];

  const toBeUpgraded: UserLayout[] = [];

  res.data.forEach((e) => {
    let shouldAdd: Boolean = false;
    const parsed: LAYOUT = JSON.parse(e.userLayoutJson);
    parsed.columns.forEach((col) => {
      if (typeof col.id == "number") {
        col.id = `${col.component}-${uuidv4()}`;
        shouldAdd = true;
      }
    });
    const layout: UserLayout = {
      ...e,
      userLayoutJson: parsed,
    };

    if (shouldAdd == true) {
      toBeUpgraded.push(layout);
    } else {
      arr.push(layout);
    }
  });
  if (toBeUpgraded.length != 0) {    
    const upgradeRes = await upgradeColIdsForLayouts(toBeUpgraded);
    useToastStore().addToast("info", `Upgraded ${upgradeRes.length} old layouts`);
    arr.concat(upgradeRes);
  }

  return arr;
}
async function upgradeColIdsForLayouts(
  toBeUpgraded: UserLayout[]
): Promise<UserLayout[]> {
  const upgraded: UserLayout[] = [];
  for (const item of toBeUpgraded) {
    const res = await updateLayout(item);
    upgraded.push(res);
  }
  return upgraded;
}

/**
 * Create user layout
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if .
 */
export async function saveLayout(
  item: UserLayout,
  userId: string
): Promise<UserLayout> {
  // try {
  const res = await axiosInstance.post("/api/UserSettings/CreateUserLayout", {
    userId: userId,
    userLayoutJson: JSON.stringify(item.userLayoutJson),
  });
  if (userId == res.data.userId) {
    return {
      _id: res.data._id,
      userLayoutJson: JSON.parse(res.data.userLayoutJson),
    };
  } else {
    throw new Error("User id of layout not the same as user id");
  }
  // } catch (err) {
  //   console.error(err);
  //   return Promise.reject(err);
  // }
}
/**
 * Update user preference - flags, defaults, layouts etc
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function updateLayout(item: UserLayout): Promise<UserLayout> {
  // try {
  const res = await axiosInstance.post("/api/UserSettings/updateUserLayout", {
    id: item._id,
    jsonString: JSON.stringify(item.userLayoutJson),
  });

  const updated: UserLayout = {
    _id: res.data._id,
    userLayoutJson: JSON.parse(res.data.userLayoutJson),
  };
  return updated;
  // } catch (err) {
  //   console.error(err);
  //   return Promise.reject(err);
  // }
}
/**
 * Delete user preference - flags, defaults, layouts etc
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function deletePreferences(): Promise<void> {
  // try {
  const res = await axiosInstance.get(
    "/api/UserSettings/DeleteUserPreferenceByUserId"
  );
  return Promise.resolve();
  // } catch (err) {
  //   console.error(err);
  //   return Promise.reject(err);
  // }
}

/**
 * Delete user Layout
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function deleteUserLayoutById(id: number): Promise<void> {
  try {
    const res = await axiosInstance.get(
      `/api/UserSettings/DeleteUserLayoutById?Id=${id}`
    );
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

interface ClientCodes {
  connectionId: string;
  allClientCodes: string[];
}

/**
 * Subscribe to User Added SubAccs
 *
 *
 * @function
 * @returns {Promise<boolean>} A promise that resolves to true if authentication is successful, false otherwise.
 */
export async function subscribeSubAccs(item: ClientCodes): Promise<void> {
  try {
    const res = await axiosInstance.post(
      "/api/UserSettings/SubscribeSubAccounts",
      item
    );
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}

/**
 * Load market announcements
 *
 *
 * @function
 * @returns {Promise<any>} loads list of market announcements.
 */
export async function loadAnnouncements(): Promise<MarketAnnouncement[]> {
  try {
    const res = await axiosInstance.get("/api/StaticData/GetAnnouncements");
    return Promise.resolve(res.data);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}
/**
 * Load MTM but the prices
 *
 *
 * @function
 * @returns {Promise<any>} loads list of market announcements.
 */
export async function loadPricesForMTM(): Promise<MtmPriceViewModel[]> {
  try {
    const res = await axiosInstance.get("/api/StaticData/GetPrices");
    return Promise.resolve(res.data);
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
}
/**
 * Load MTM but the prices
 *
 *
 * @function
 * @returns {Promise<any>} loads list of market announcements.
 */
export async function loadContractDetailsForDepth(
  contract: string,
  instrument: string
): Promise<DepthContractOverview | null> {
  try {
    const res = await axiosInstance.post(
      `/api/StaticData/GetContractDetails`,
      {
        contractName: contract,
        instrument: instrument,
      },
      {
        silentError: true,
      }
    );
    return Promise.resolve(res.data);
  } catch (err) {
    console.error(err);
    return Promise.resolve(null);
  }
}
