import { nextTick, onMounted, onUnmounted, ref } from "vue";
import { useSystemSocketStore } from "@/store/systemSocket";
import {
  DailyTrend,
  IContractDate,
  MarketDisplayItemContract as MainModel,
  MtmViewModel,
  PublishAll,
} from "@/models/marketData";
import { createTypedArray } from "@/utils/useWebsocket";
import { useMTMStore } from "@/store/mtm";
import { useToastStore } from "@/store/toastStore";
import { useContractDateStore } from "@/store/contractDate";
import useConsoleLogger from "./useConsoleLogger";
import { SocketResponse } from "@/models/trading";
import { useTradeHistory } from "@/store/tradeHistory";
import { Socket } from "dgram";
import { useMarketDisplayStore } from "@/store/marketDisplay";

export const useSystemSocket = () => {
  const systemSocketStore = useSystemSocketStore();
  const toastStore = useToastStore();

  const initialize = async () => {
    if (systemSocketStore.isHotReload || !systemSocketStore.socket) {
      await systemSocketStore.initializeSocket(async () => {
        await systemSocketStore.socket?.invoke<Socket>("SubscribeToDailyTrend");

        return Promise.resolve();
      }, [
        {
          name: "MTMInit",
          func: handleMTMUpdate,
        },
        {
          name: "ContractDateInit",
          func: handleContractDatesUpdates,
        },
        {
          name: "DailyTrendsInit",
          func: handleDailyTrendUpdates,
        },
        {
          name: "DailyTrendUpdate",
          func: handleDailyTrendUpdates,
        },
        {
          name: "DownloadsCompleted",
          func: handleSystemReady,
        },
      ]);
    }
  };
  const handleDisconnectCheck = async () => {
    if (systemSocketStore.socket?.state == "Connected") {
      const res = await systemSocketStore.socket?.invoke<SocketResponse>(
        "DisconnectCheck"
      );
      //boolean fails for some reason
      const temp = res.Message == "True" ? true : false;
      return temp;
    }
    return false;
  };

  onUnmounted(async () => {
    // await systemSocketStore.socket?.invoke<Socket>("UnsubscribeFromDailyTrend");
    systemSocketStore.closeSocket();
  });
  const handleDailyTrendUpdates = (message: string) => {
    const store = useTradeHistory();
    try {
      const temp = createTypedArray<DailyTrend>(message);
      temp.forEach((e) => {
        store.updateItem(e);
      });
      // useConsoleLogger.log(
      //   "Parsed DAILY TREND update : ",
      //   temp.length,
      //   temp,
      //   store.data.length
      // );
      // toastStore.addToast("success", `Added ${temp.length} daily trend`);
      //
    } catch (err) {
      useConsoleLogger.error("error parsing daily trend-", message, err);
      return Promise.reject(err);
    }
  };
  function batchUpdateItems(updatedItems: MainModel[]) {
    const store = useMarketDisplayStore();
    store().$patch((state) => {
      updatedItems.forEach((updatedItem) => {
        const index = state.data.findIndex(
          (e) => e["contract"] === updatedItem["contract"]
        );
        if (index === -1) {
          // console.log("Push items")
          state.data.push(updatedItem);
        } else {
          // Assuming deepMerge is a utility function for deep merging objects
          store().deepMerge(
            state.data[index],
            updatedItem,
            "systemSocketBatch"
          );
        }
      });
    });
  }
  const loadMarketDataChunks = async () => {
    if (
      systemSocketStore.socket &&
      systemSocketStore.socket.state == "Connected"
    ) {
      systemSocketStore.socket.on("MarketDisplayCompleted", (msg) => {
        systemSocketStore.socket?.off("MarketDisplayChunk");
        systemSocketStore.socket?.off("MarketDisplayCompleted");
        console.log("Expecting completed message: ", msg);
        return Promise.resolve();
      });
      systemSocketStore.socket.on("MarketDisplayChunk", (msg) => {
        const temp = createTypedArray<MainModel>(msg);
        batchUpdateItems(temp);
      });
      console.log("Invoke chunking");
      await systemSocketStore.socket.invoke<SocketResponse>(
        "GetMarketDisplayChunks"
      );
    }
  };
  const loadDailyTrend = async () => {
    if (
      systemSocketStore.socket &&
      systemSocketStore.socket.state == "Connected"
    ) {
      const promises = [
        systemSocketStore.socket.invoke<SocketResponse>(
          "PublishAllData",
          PublishAll.DailyTrends,
          null
        ),
      ];
      return await Promise.all(promises);
    }
    return Promise.resolve();
  };
  const handleMTMUpdate = (message: string) => {
    const mtm = useMTMStore("useSystemSocket", []);
    try {
      const temp = createTypedArray<MtmViewModel>(message);
      temp.forEach((e) => {
        mtm.store.updateItem(e);
      });
      useConsoleLogger.log(
        "Parsed MTM update : ",
        temp.length,
        temp,
        mtm.store.data.length
      );
      toastStore.addToast("success", `Added ${temp.length} mtm`);
      //
    } catch (err) {
      useConsoleLogger.error("error parsing MTMInit", message, err);
      return Promise.reject(err);
    }
  };
  const handleSystemReady = (val: any) => {
    const de = val == "True" ? true : false;
    if (de == true) {
      systemSocketStore.isBackendReadyForTrading = true;
    }
  };
  const loadMTM = async () => {
    if (
      systemSocketStore.socket &&
      systemSocketStore.socket.state == "Connected"
    ) {
      const promises = [
        systemSocketStore.socket.invoke<SocketResponse>(
          "PublishAllData",
          PublishAll.MTM,
          null
        ),
      ];
      return await Promise.all(promises);
    }
  };
  const handleContractDatesUpdates = (message: string) => {
    const contracts = useContractDateStore();
    try {
      const temp = createTypedArray<IContractDate>(message);
      temp.forEach((e) => {
        contracts.updateItem(e);
      });
      useConsoleLogger.log("CONTRACT DATE: Parsed update : ", temp);
      toastStore.addToast("success", `Added ${temp.length} contract dates`);
      //
    } catch (err) {
      useConsoleLogger.error("error parsing ContractDateInit", message, err);
      return Promise.reject(err);
    }
  };
  const loadContractDates = async () => {
    if (
      systemSocketStore.socket &&
      systemSocketStore.socket.state == "Connected"
    ) {
      const promises = [
        systemSocketStore.socket.invoke<SocketResponse>(
          "PublishAllData",
          PublishAll.ContractDate,
          null
        ),
      ];
      return await Promise.all(promises);
    }
  };
  return {
    systemSocketStore,
    loadMTM,
    loadContractDates,
    loadDailyTrend,
    initialize,
    loadMarketDataChunks,
    handleDisconnectCheck,
  };
};
